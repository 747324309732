
.login-layout {
  background-image: url("/public/images/background.webp");
  height: 100%;
  background-size: contain;
  background-repeat: repeat-y;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
 
.header-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 14px; 
}
.header-bar {  
  position: relative;
  width: 90vw; 
  margin: 5vh auto auto;
  background-color: #c30900;
  padding: 10px 20px;
  color: white;
  text-align: center; 
  font-size: 16px; 
  border-radius: 24px 24px 0 0;
  box-shadow: 0px 10px 10px 0px gray;
} 

.header-bar::before, .header-bar::after {
  content: '';
  position: absolute;
  top: 30px;
  width: 30px;
  height: 100%;
  background-color: #c30900;
}

.header-bar::before {
  right: -0;
  clip-path: polygon(0 0, 100% 0, 100% 88%, 89% 84%, 81% 81%, 61% 77%, 16% 72%, 0 50%);
 
}

.header-bar::after {
  left: -0;
  clip-path: polygon(100% 0, 0 0, 0 87%, 12% 84%, 20% 81%, 36% 77%,56% 75%, 100% 50%)
}

.logo {
  max-width: 240px;
  position: absolute;
  left: 100px; 
  filter: drop-shadow( 2px 2px 15px rgba(0, 0, 0, .3))
}

.header-text h1 {
  font-size: 46px;
  color: white; 
  margin: 0;
  font-family: 'Gotham Pro';
  font-weight: 900; 
  font-style: normal;
}

.header-text p { 
  font-size: 19px; 
  color: white; 
  margin: 0;
  text-align: right;
  font-family: 'Gotham Pro';
  font-weight: bold;
  font-style: normal;
}
 
.content-row {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}
.col1 {
  flex: 0 0 50%;    
  display: flex;
  align-items: stretch;
  width: 100%;
  justify-content: center;
  flex-direction: column;
}
.col1 p {
  position: relative;
  font-size: 18px;
  font-family: 'Gotham Pro';
  font-weight: 500;
  font-style: normal;
  padding-left: 40px;
}
 
 .p1::before { 
  content: ''; 
  position: absolute; 
  width: 20px;
  height: 20px;
  background-color: #c30900;
  top: 0;
  left: 10px;
}
 
.p2::before {
   content: ''; 
  position: absolute; 
  width: 20px;
  height: 20px;
  background-color: #c30900;
  top: 0;
  left: 10px;

}
.col1 h3 {
  font-size: 24px;
  font-family: 'Gotham Pro';
  font-weight: bold; 
  font-style: normal;
}
.col2 {
  flex: 0 0 35%;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.login-container {
  width: 100%;
  max-width: 480px;
  padding: 20px;
}
 
.login-card {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
 
.login-title {
  text-align: center;
  margin-bottom: 24px;
  color: #000;
}
 
.login-footer {
  text-align: left;
  margin-top: 15px;
}
.description {
  margin: 4% 5% 20px;
}
@media only screen and (max-width: 600px) {
  .header-bar{
    height: 300px;
    background-color: transparent;
    padding: 16px;
    margin: 0;
    width: 100%;
    box-shadow: none;
    display: flex; 
  }
  
  .header-container {
    padding: 0;
    width: 100%;
    display: flex;        
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .header-text {
    display: none;
  }
  .header-bar::before,.header-bar::after {
    display: none;
  }
  .logo { 
    position: relative;
    left: auto;
  } 
  .content-row {
    flex-direction: column;
    justify-content: flex-start;
    align-items: start;
  }
  .col1, .col2 {
    flex: none;
  }
  .p1 {
    font-size: 24px;
  }
  .p2 {
    font-size: 16px;
  }
  .description { 
    margin: 4% 3% 10px;
  }
  .login-container {
    padding: 0;
  }
  .ant-card-body {
    padding: 0;
  }
}