.price {
  font-size: 16px; 
}

.old-price {
  position: relative;
  font-size: 12px;
  color: #797878; 
  margin-left: 8px
   
}
.old-price > span {
  font-size: 12px; 
  font-weight: 400;
}
.price-discount { 
  color: #fd1100;
  font-size: 16px; 
  display: inline-block;
  margin-bottom: 8px;
  
}

.price-discount > span, .price > span {
  font-size: 14px;
  font-weight: 500;
}

 
.discount-ribbon {
  position: absolute;
  top: -28px;
  right: -16px;
}
 
.product-row {
  position: relative; 
}
.filters {
  padding: 8px;
}

.collapse {
  background-color: white;
}

.collapse > .ant-collapse-item { 
  border-bottom: 1px solid #e5e5e5 !important;

}

.collapse > .ant-collapse-item:last-child  { 

}