.price {
  font-size: 18px; 
}
  
.old-price {
  position: relative;
  font-size: 14px;
  color: #797878; 
  margin-left: 8px
   
}
.old-price > span { 
  font-size: 14px; 
  font-weight: 400;
}
.price-discount { 
  color: #fd1100;
  font-size: 18px;  
  display: inline-block;
  margin-bottom: 8px;
  
}

.price-discount > span, .price > span {
  font-size: 16px;
  font-weight: 500;
}
.props {
  margin-top: 8px; 
  display: flex;
  flex-direction: column; 
  width: 100%;
}
.props > .product-prop__item {
  width: 100%;  
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
}
 .props > .product-prop__item::after { 
  float: left;
  width: 0;
  color: #bcbcbc;
  white-space: nowrap;
  content: "...................." "...................." "...................." "...................." "...................." "...................." "...................." "...................." "...................." "...................." "...................." "...................." "...................." "...................." "...................." "...................."; 

}
.props > .product-prop__item span:first-child {
  position: relative; 
  background-color: white;
  padding-right: 5px
}
.props > .product-prop__item span:last-child {
  position: relative;
  background-color: white;
  float: right;
  padding-left: 5px;
  font-weight: 600;
}