.layout {
  background-image: url('/public/images/background.webp');
  background-color: white;
  background-repeat: no-repeat;
  background-position: center ;
  background-size: contain;
}
@media only screen and (max-width: 600px) {
  .layout {
    background-image: url('/public/images/background.webp');
    background-color: white;
    background-repeat: no-repeat;
    background-position: center ;
    background-size: cover;
  }
}
.container {
  max-width: 408px;
  margin: auto;
} 