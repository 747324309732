.sticky {
  position: sticky;
  top: 12px
}
.right-side {

  position: relative;
}
@media (max-width: 992px) {
  .sticky {
    position: relative; 
  }
}

 
 
.ribbon {
  position: absolute;
  top: -28px;
  right: -16px;
}