.product {
  background-color: white;
  padding: 16px;
}
.product-tr > .ant-table-cell {
  padding: 0 !important; 
}
.product .product-card {
  margin: 0 16px;
}
.product .product-card > span {
  font-size: 18px ;
  font-weight: 600;
}
.product .product-card__content {
  margin-top: 8px; 
  display: flex;
  flex-direction: column; 
  width: 100%;
}
.product .product-card__content > .product-prop__item {
  width: 100%;  
  position: relative;
  margin-bottom: 10px;
  overflow: hidden;
}
.product .product-card__content > .product-prop__item::after { 
  float: left;
  width: 0;
  color: #bcbcbc;
  white-space: nowrap;
  content: "...................." "...................." "...................." "...................." "...................." "...................." "...................." "...................." "...................."; 

}

.product .product-card__content > .product-prop__item span:first-child {
  position: relative; 
  background-color: white;
  padding-right: 5px
}
.product .product-card__content > .product-prop__item span:last-child {
  position: relative;
  background-color: white;
  float: right;
  padding-left: 5px;
  font-weight: 600;
}